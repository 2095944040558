import React, {useState} from 'react'
import {Link} from 'gatsby'
import {motion} from 'framer-motion'
import tw, {styled, theme} from 'twin.macro'
import LogoType from '../../icons/LogoType.js'
import FloatMenu from '../../components/general/FloatMenu'

const Wrapper = styled.div`
  [aria-current]:not([aria-current='false']) {
    color: ${theme`colors.white`};
  }
  ${tw`2xl:max-w-screen-2xl my-0 mx-auto h-0`}
`

const Header = tw.header`w-full antialiased`
const Nav = tw(motion.div)`z-50`

const LogoContainer = tw.div``

const Container = tw(motion.div)`
  relative top-0 left-0 pt-6 pl-4 md:p-10 z-50 w-full md:w-auto
`

const List = tw.ul`ml-4 md:ml-6 font-bold text-sm md:text-lg tracking-[-0.01em]  text-gray-lightest leading-4 md:leading-5`

const Item = tw.li`cursor-auto pt-1 hover:text-white transition-colors duration-300`

const Scrim = styled.div`
  height: 600px;
  width: 400px;
  margin: -4rem 0 0 -4rem;
  top: 0;
  z-index: -1;
  position: absolute;
  background: radial-gradient(
    black 0%,
    rgba(0, 0, 0, 0.18) 30%,
    rgba(0, 0, 0, 0.15) 40%,
    rgba(0, 0, 0, 0.075) 60%,
    rgba(0, 0, 0, 0.037) 65%,
    rgba(0, 0, 0, 0.019) 66%,
    rgba(0, 0, 0, 0) 100%
  );
`

const Menu = () => {
  return (
    <Wrapper>
      <FloatMenu
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{delay: 1, ease: [0.22, 1, 0.36, 1], duration: 1.22}}
      ></FloatMenu>
    </Wrapper>
  )
}

export default Menu
