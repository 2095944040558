module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.2_n5kw4o2eayhl3xhcf4xfhhdm5y/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pauline Maudy","short_name":"Pauline Maudy","background_color":"#5d7368","theme_color":"#292929","start_url":"/","display":"standalone","icon":"/opt/build/repo/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fae7bd006d5a396cf2ac979e0d621435"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.16.2_@emotion+react@11.11.4_@types+react@18.2.79_react@18.2.0__@mdx-_mq3kxpon7bq5ot4jtgy3aa4daq/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.2._u5gym3ftkvhavawgh5zcoy6n5y/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__ad7g7m3ogy5tncjut5ddt3fmga/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
